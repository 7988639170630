import React from 'react'
import styled, { keyframes } from 'styled-components'
import { rgba } from 'polished'

import { widowFix } from 'utils/string'

import { borderRadius } from 'styles/variables'
import { primaryColor, black, white } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { largerThan, smallerThan } from 'styles/media'

import Heading from 'components/Heading'
import RichText from 'components/RichText'

const borderDance = keyframes`
  0% {
    background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px;
  }
  100% {
    background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px;
  }
`

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  position: relative;
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  background-color: ${rgba(black, 0.15)};
  padding: ${spacing.large};

  &:nth-child(2):after,
  &:nth-child(3):after,
  &:nth-child(6):after,
  &:nth-child(7):after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    width: 32px;
    margin-left: -32px;
    background: linear-gradient(90deg, ${rgba(white, 0.3)} 50%, transparent 50%),
      linear-gradient(90deg, ${rgba(white, 0.3)} 50%, transparent 50%),
      linear-gradient(0deg, ${rgba(white, 0.3)} 50%, transparent 50%),
      linear-gradient(0deg, ${rgba(white, 0.3)} 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 10px 2px, 10px 2px, 2px 10px, 2px 10px;
    background-position: 0px 0px, 40px 1px, 0px 1px, 40px 0px;
    animation: ${borderDance} 20s infinite linear;

    ${smallerThan.medium`
      display: none;
    `};

    ${largerThan.large`
      width: 40px;
      margin-left: -40px;
    `};
  }

  &:nth-child(3):after,
  &:nth-child(7):after {
    transform: scaleX(-1);
  }

  &:nth-child(2):before,
  &:nth-child(4):before,
  &:nth-child(6):before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 2px;
    width: 32px;
    margin-left: -32px;
    transform: rotate(90deg) translateX(100%);
    transform-origin: bottom right;
    background: linear-gradient(90deg, ${rgba(white, 0.3)} 50%, transparent 50%),
      linear-gradient(90deg, ${rgba(white, 0.3)} 50%, transparent 50%),
      linear-gradient(0deg, ${rgba(white, 0.3)} 50%, transparent 50%),
      linear-gradient(0deg, ${rgba(white, 0.3)} 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 10px 2px, 10px 2px, 2px 10px, 2px 10px;
    background-position: 0px 0px, 40px 1px, 0px 1px, 40px 0px;
    animation: ${borderDance} 20s infinite linear;

    ${smallerThan.medium`
      display: none;
    `};

    ${largerThan.large`
      width: 40px;
      margin-left: -40px;
    `};
  }

  ${largerThan.medium`
    padding: ${spacing.xLarge};
    order: ${(props) => props.order};
  `};

  ${largerThan.large`
    padding: ${spacing.xxLarge};
  `};
`

const Number = styled(Heading)`
  color: ${primaryColor};
  margin-bottom: ${spacing.small};
`

const Name = styled(Heading)`
  margin-bottom: ${spacing.small};

  ${largerThan.medium`
    margin-bottom: ${spacing.medium};
  `};
`

const Extra = styled.div`
  margin-top: -${spacing.small}; /* Move closer to description */
`

const Step = ({ number, name, description, extra, order }) => (
  <Container order={order}>
    <Number size={6} tag="span">
      0{number}
    </Number>
    <Name size={3}>{name}</Name>
    <RichText>
      <p>{widowFix(description)}</p>
      {extra && <Extra>{extra}</Extra>}
    </RichText>
  </Container>
)

export default Step
