import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { rgba } from 'polished'

import { borderRadius } from 'styles/variables'
import { white } from 'styles/colors'
import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import RichText from 'components/RichText'
import Button from 'components/button/Button'

const Container = styled.div`
  padding: ${spacing.large};
  background-color: ${rgba(white, 0.05)};
  border-radius: ${spacing.scale(borderRadius, 1.25)};

  ${largerThan.medium`
    padding: ${spacing.xLarge};
    order: 7;
  `};

  ${largerThan.large`
    padding: ${spacing.xxLarge};
  `};
`

const Action = () => (
  <Container>
    <Heading size={4}>Ready to get started?</Heading>
    <RichText>
      <p>
        We work with companies small and large who are looking to double down on
        their digital initiatives.
      </p>
      <Button to="/contact/" size="small">
        Get in touch
      </Button>
    </RichText>
  </Container>
)

export default Action
