import React from 'react'
import { ThemeProvider } from 'styled-components'

import { themes } from 'styles/theme'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import RichText from 'components/RichText'
import Heading from 'components/Heading'
import Steps from './Steps'

const Workflow = () => (
  <ThemeProvider theme={themes.dark}>
    <Section id="workflow">
      <SectionBlock>
        <Wrapper size="narrow">
          <Heading size={3} tag="h2">
            Our workflow
          </Heading>
          <RichText>
            <p>
              Here's what you can expect when partnering with us on your digital
              initiatives.
            </p>
          </RichText>
        </Wrapper>
      </SectionBlock>

      <SectionBlock>
        <Wrapper>
          <Steps />
        </Wrapper>
      </SectionBlock>
    </Section>
  </ThemeProvider>
)

export default Workflow
