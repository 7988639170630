import React from 'react'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby'

import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Step from './Step'
import Action from './Action'

export const STEPS = [
  {
    name: 'Planning',
    description:
      'We work with your stakeholders to identify current problems, brainstorm possible solutions, and assess their technical feasibility.',
    order: 1,
  },
  {
    name: 'Concretization',
    description:
      'We translate high-level solutions into well-documented software architecture, product requirements, and developer-ready technical specifications.',
    order: 2,
  },
  {
    name: 'Communication',
    description:
      'We create a dedicated communication channel for easy back-and-forth, combined with weekly check-ins so you can keep track of our progress.',
    extra: (
      <p>
        <AnchorLink href="#toolkit">View our tools</AnchorLink>
      </p>
    ),
    order: 4,
  },
  {
    name: 'Production',
    description:
      'We assemble a technical team, define development milestones, and segment the workload by sprint. Our progress is made easily accessible and we iterate based on your feedback.',
    extra: (
      <p>
        <Link to="/services/development/">
          View our development capabilities
        </Link>
      </p>
    ),
    order: 3,
  },
  {
    name: 'Testing',
    description:
      'We conduct frequent code reviews, establish automated testing pipelines, and set up acceptance testing workflows to ensure that requirements are met and deployed solutions remain stable.',
    order: 5,
  },
  {
    name: 'Analyze',
    description:
      'We help you define KPIs and high value metrics and targets. We then implement real-time analytics and tracking solutions that will provide actionable insight into your business.',
    order: 6,
  },
  {
    name: 'Iterate',
    description:
      'We use our observations from quantifiable data paired with user feedback to continuously refine and improve the solution in place.',
    order: 8,
  },
]

const Container = styled.div`
  display: grid;
  grid-gap: ${spacing.medium};

  ${largerThan.small`
    grid-gap: ${spacing.large};
  `};

  ${largerThan.medium`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${spacing.xLarge};
  `};

  ${largerThan.large`
    grid-gap: ${spacing.xxLarge};
  `};
`

const Steps = () => (
  <Container>
    {STEPS.map((step, i) => (
      <Step
        key={i}
        number={i + 1}
        name={step.name}
        description={step.description}
        extra={step.extra}
        order={step.order}
      />
    ))}
    <Action />
  </Container>
)

export default Steps
