import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Lead } from 'styles/typography'

import PageHeader from 'components/PageHeader'
import Section, { SectionBlock } from 'components/Section'
import MetaTags from 'components/MetaTags'
import Heading from 'components/Heading'
import Wrapper from 'components/Wrapper'
import RichText from 'components/RichText'
import ServiceGrid from 'components/ServiceGrid'
import Related from 'components/industries/Related'
import StrategyToolkit from 'components/services/Toolkit/StrategyToolkit'
import Workflow from 'components/services/Workflow'
import Explore from 'components/services/Explore'

const Strategy = ({ location }) => (
  <>
    <MetaTags title="Digital product strategy and consulting | Volume7" />

    <PageHeader
      breadcrumbs={[
        {
          label: 'Services',
          link: '/services/',
        },
        {
          label: 'Strategy',
        },
      ]}
      heading="Digital product strategy"
      description="We help you define business goals, identify success metrics, and establish a clear vision for your product and its roadmap."
      figure={
        <StaticImage
          src="../assets/images/services/strategy.jpg"
          layout="fullWidth"
          alt=""
          placeholder="blurred"
        />
      }
    />

    <Section flushTop>
      <Wrapper size="narrow">
        <SectionBlock>
          <RichText>
            <Lead>
              Figuring out <em>what</em> to build is just as important as{' '}
              <em>how</em> it's built, which is why we've made product strategy
              one of our core focuses.
            </Lead>

            <p>
              We work collaboratively with our clients to identify and
              understand their unique business challenges, while helping them
              assess various approaches through our software and product
              expertise. We then recommend the solution best suited to your
              business restrictions, technical limitations, and time/resource
              constraints, and make sure its execution is flawless.
            </p>
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Startup DNA
          </Heading>
          <RichText>
            <p>
              Our team is comprised of designers and developers with extensive
              experience working on complex products at top startups. Contrary
              to most digital agencies, we know how products are built and we
              solve your software challenges with that same pragmatism and
              focus.
            </p>
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Problem first
          </Heading>
          <RichText>
            <p>
              We embrace a problem-first mindset, in which we focus on providing
              complete solutions to <em>actual</em> problems. We're not
              interested in building software for problems that don't really
              exist, nor create new problems to benefit our bottom line.
            </p>
          </RichText>
        </SectionBlock>
      </Wrapper>
    </Section>

    <Workflow />

    <Section>
      <Wrapper size="narrow">
        <SectionBlock>
          <Heading size={3} tag="h2">
            Minimum lovable product
          </Heading>
          <RichText>
            <p>
              Our strength is defining and prioritizing a roadmap that allows
              for fast market penetration and maximizes value proposition. While
              we aim for simplicity and elegance in our work, we set out to
              build software that not only provides differentiated business
              value, but is also loved by its users.
            </p>
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Seamless transition
          </Heading>
          <RichText>
            <p>
              Although we pride ourselves on building long-lasting relationships
              with all clients, we recognize the importance of a smooth handoff.
              We'll provide thorough documentation, best practices, source
              files, and anything else that will ensure your investment with us
              will continue to pay dividends long after the project has ended.
            </p>
          </RichText>
        </SectionBlock>
      </Wrapper>
    </Section>

    <Section hasBorder id="services">
      <Wrapper size="narrow">
        <ServiceGrid
          title="Services"
          services={[
            {
              name: 'Business strategy',
              list: [
                'Market research',
                'Concept validation',
                'Feasibility assessment',
              ],
            },
            {
              name: 'Project management',
              list: [
                'Prioritization/resource planning',
                'Roadmapping',
                'Forecasting',
                'Agile methodologies',
                'Coaching',
              ],
            },
            {
              name: 'Testing',
              list: [
                'Focus groups/interviews',
                'UAT workflow',
                'Customer insights gathering',
              ],
            },
            {
              name: 'Analytics',
              list: [
                'Tagging strategy',
                '3rd party integration',
                'External data consolidation',
                'Visualization',
              ],
            },
          ]}
        />
      </Wrapper>
    </Section>

    <StrategyToolkit />

    <Related projects={['aldo', 'vape']} />
    <Explore location={location} />
  </>
)

export default Strategy
