import React from 'react'

import Toolkit from 'components/services/Toolkit'

import Jira from 'assets/images/services/toolkit/jira.svg'
import Confluence from 'assets/images/services/toolkit/confluence.svg'
import Monday from 'assets/images/services/toolkit/monday.svg'
import Slack from 'assets/images/services/toolkit/slack.svg'
import Trello from 'assets/images/services/toolkit/trello.svg'
import Notion from 'assets/images/services/toolkit/notion.svg'
import Airtable from 'assets/images/services/toolkit/airtable.svg'
import GitLab from 'assets/images/services/toolkit/gitlab.svg'
import Google from 'assets/images/services/toolkit/google.svg'
import Loom from 'assets/images/services/toolkit/loom.svg'
import GitHub from 'assets/images/services/toolkit/github.svg'

const StrategyToolkit = () => (
  <Toolkit
    heading="Tools of choice"
    description="We're familar with industry-standard software and love using modern tools, but we're comfortable working with what you already know and use."
    items={[
      {
        figure: <Jira />,
        label: 'Jira',
      },
      {
        figure: <Trello />,
        label: 'Trello',
      },
      {
        figure: <Monday />,
        label: 'Monday',
      },
      {
        figure: <Confluence />,
        label: 'Confluence',
      },
      {
        figure: <Notion />,
        label: 'Notion',
      },
      {
        figure: <Google />,
        label: 'G Suite',
      },
      {
        figure: <Slack />,
        label: 'Slack',
      },
      {
        figure: <GitHub />,
        label: 'GitHub',
      },
      {
        figure: <GitLab />,
        label: 'GitLab',
      },
      {
        figure: <Loom />,
        label: 'Loom',
      },
      {
        figure: <Airtable />,
        label: 'Airtable',
      },
    ]}
  />
)

export default StrategyToolkit
